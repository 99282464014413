.chat-overlay-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.chat-toggle-button {
    background: linear-gradient(135deg, #3d0a5f, #000000);
    color: #17bbc6;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 999;
}

.chat-toggle-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

.chat-window {
    position: absolute;
    bottom: -11%;
    right: -20%;
    width: 380px;
    background: linear-gradient(135deg, #212121, #000000);
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.7);
    overflow: hidden;
    z-index: 1001;
}

.chat-header {
    background: linear-gradient(135deg, #3d0a5f, #000000);
    color: #17bbc6;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.chat-messages {
    height: 600px;
    overflow-y: auto;
    padding: 10px;
    background: #2b2b2b;
    color: #ffffff;
    box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.5);
    word-wrap: break-word;
    word-break: break-word;
}

.chat-message {
    display: flex;
    flex-direction: column; /* Stack username above message */
    align-items: flex-start;
    text-align: left;
    margin-bottom: 10px;
    font-size: 14px;
    color: #e6e6e6;
    background: #3d3d3d;
    padding: 8px 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    width: 100%; /* Keep messages full width */
    max-width: 100%; /* Prevent shrinking */
    transition: transform 0.2s ease;
}

/* Messages sent by others (default left alignment) */
.chat-message:not(.self-message) {
    justify-content: flex-start;
    border-top-left-radius: 0;
}

/* Messages sent by the logged-in user (right alignment) */
.chat-message.self-message {
    align-self: flex-end;
    background: #444444;
    border-top-right-radius: 0;
    align-items: flex-end; /* Align username and message to the right */
    text-align: right;
}

/* Username styles */
.chat-username {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
    color: #17bbc6;
    display: block;
    word-break: break-word;
}

.chat-message.self-message .chat-username {
    margin-left: 10px;
    margin-right: 0;
}

.chat-username-link {
    text-decoration: none;
    color: inherit;
}

.chat-input-container {
    display: flex;
    border-top: 1px solid #6c3483;
    padding: 10px;
    background: #212121;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
}

.chat-input {
    flex: 1;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 14px;
    color: #ffffff;
    background: #2b2b2b;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.5);
}

.chat-input::placeholder {
    color: #bbbbbb;
}

.send-button {
    background: linear-gradient(135deg, #6c3483, #3d0a5f);
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    margin-left: 10px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.send-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.7);
}

.chat-timestamp {
    font-size: 11px;
    color: #bbbbbb;
    margin-top: 5px;
    align-self: flex-end; /* Aligns the timestamp to the right */
    text-align: right; /* Ensures proper alignment for multi-line messages */
    width: 100%; /* Takes full width of the message box */
}

/* Profile picture in chat header */
.chat-header-profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Circular profile picture */
    margin-right: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    display: inline-block; /* Ensures it aligns with the username */
    vertical-align: middle; /* Aligns it with text */
}

/* Profile picture in chat messages */
.chat-profile-picture {
    width: 25px;
    height: 25px;
    border-radius: 50%; /* Circular profile picture */
    margin-right: 10px; /* Space between profile picture and username */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    flex-shrink: 0; /* Prevents the profile picture from shrinking */
}

/* Align the profile picture and username in chat messages */
.chat-message-header {
    display: flex;
    align-items: center; /* Vertically align profile picture and username */
    margin-bottom: 1px;
}
