body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}
.ui.basic.segment {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.home-main-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 20px;
    max-width: 800px;
    margin-top: 50px;
}

.home-content-block {
    background: linear-gradient(135deg, #3d0a5f, #000000);
    border: 2px solid #6c3483;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    max-width: 1200px;
    width: 100%;
    font-weight: bold;
    margin-top: 50px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-content-block:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

.home-titles {
    font-size: 28px;
    margin-bottom: 20px;
    color: #17bbc6;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.home-main-text {
    font-size: 18px;
    line-height: 1.6;
    color: #ffffff;
}

@media all and (max-width: 600px) {
    .home-content-block {
        padding: 20px;
        max-width: 90%;
    }

    .home-first-block {
        margin-top: 150px;
    }

    home-h2 {
        font-size: 22px;
    }

    home-p {
        font-size: 16px;
    }
}

/* Modal Style Adjustments */
.home-modal-content {
    background-color: #212121;
    color: #e6e6e6;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
}

.updates-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.update-item {
    background: #2b2b2b;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.update-title {
    font-size: 22px;
    color: #17bbc6;
    margin-bottom: 10px;
}

.update-date {
    font-size: 14px;
    color: #bbbbbb;
    margin-bottom: 10px;
}

.update-description {
    font-size: 16px;
    color: #ffffff;
}

.user-counts {
    position: absolute;
    top: 10px;
    right: 10px;
    background: linear-gradient(135deg, #3d0a5f, #000000);
    border: 2px solid #6c3483;
    padding: 15px 20px;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    text-align: center; /* Align text to the center */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    font-weight: bold;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.user-counts div {
    margin-bottom: 5px;
    color: #17bbc6; /* Matches the highlight color from your titles */
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    line-height: 1.5; /* Adjust line height for consistent spacing */
}

.user-counts div:last-child {
    margin-bottom: 0;
}

.user-counts:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

