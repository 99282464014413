/* CGB Container */
.cgb-container {
  display: flex;
  justify-content: center;
  overflow: visible; /* Allow it to grow */
  overflow-x: hidden;
}

/* Main Content */
.cgb-main-content {
  flex: 1;
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  transition: margin-right 0.6s ease;
}

/* Server Details Section */
.cgb-server-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 25px;
  background-color: #1e1e1e;
  border-radius: 12px;
  border: 2px solid rgb(82, 0, 107);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  width: 90%;
  overflow: hidden;
  transition: margin-right 0.6s ease;
}

.cgb-main-content.cgb-right-sidebar-visible {
  margin-right: 270px;
}

/* Sidebar Styles */
.cgb-right-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  background-color: #222;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
  z-index: 1000;
  overflow-y: auto;
  border-left: 4px solid rgb(82, 0, 107);
}

.cgb-right-sidebar.hidden {
  transform: translateX(100%);
}

.cgb-right-sidebar.visible {
  transform: translateX(0);
}

.cgb-game-switcher {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.cgb-game-switch-button {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  background-color: #444;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.cgb-game-switch-button.active {
  background: linear-gradient(135deg, #3d0a5f, #000000);
}

.cgb-server-list {
  list-style: none;
  padding: 0 20px;
  margin: 0;
}

.cgb-server-item {
  padding: 10px;
  margin-bottom: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.cgb-server-item:hover {
  background-color: #444;
}

.cgb-server-item.active {
  background: linear-gradient(135deg, #3d0a5f, #000000);
  color: #ffffff;
}



.cgb-sidebar-visible {
  margin-right: 270px;
  margin-left: -2%;
}

/* Toggle Button */
.cgb-new-sidebar-toggle-button {
  position: fixed;
  top: 3%;
  right: 310px;
  transform: translateY(-50%);
  background-color: #333;
  color: #fff;
  border: 2px solid #555;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  z-index: 1001;
  font-size: 1.2em;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.cgb-new-sidebar-toggle-button.hidden {
  right: 10px;
}

.cgb-new-sidebar-toggle-button:hover {
  background-color: #555;
  border-color: #777;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}



.cgb-server-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 20px; /* Space between columns */
  width: 100%; /* Take full width of the server-details container */
  margin-top: 20px; /* Add some spacing above */
}

.cgb-server-details img {
  max-width: 60%; /* Make image a bit larger for emphasis */
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px; /* More pronounced rounding */
  border: 3px solid rgb(82, 0, 107); /* Highlight the image to draw attention */
}

.cgb-server-details h2 {
  margin-bottom: 12px;
  font-size: 1.75em; /* Slightly larger for emphasis */
  color: #ffffff; /* Bright white for title emphasis */
  font-weight: bold; /* Emphasize the server title */
}

.cgb-server-details p {
  margin: 8px 0;
  color: #cccccc; /* A lighter grey to improve readability */
  font-size: 1.1em; /* Slightly larger font size for easy reading */
}

/* Additional Styling for Player List */
.cgb-server-details .cgb-player-list {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 15px 0;
  color: #bbb; /* Light grey for contrast */
}

.cgb-server-details .cgb-player-list li {
  padding: 8px;
  background: linear-gradient(135deg, #000000,#3d0a5f);
  color: #fff; /* Make text stand out more when hovered */
  margin: 6px 0;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.cgb-server-details .plcgb-ayer-list li:hover {
  background-color: rgb(82, 0, 107); /* Highlight player on hover */
  color: #fff; /* Make text stand out more when hovered */
}

/* Wrapper for all playlist containers */
.cgb-server-details .cgb-playlist-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create two equal columns */
  gap: 20px; /* Add space between the items */
  width: 100%;
}

/* Individual playlist container styling */
.cgb-server-details .cgb-playlist-container {
  padding: 15px;
  background-color: #2a2a2a;
  border-radius: 8px;
  text-align: left;
  color: #ddd;
  box-sizing: border-box; /* Include padding and border in element width and height */
}

.cgb-server-details .cgb-playlist-container p {
  font-size: 1.1em;
  margin: 10px 0;
}

.cgb-server-details .cgb-playlist-container ul {
  list-style: disc inside;
  padding-left: 20px;
}

.cgb-playlist-container ul .cgb-current-map {
  background: linear-gradient(135deg, #3d0a5f, #161616);
  color: #ffffff;
  font-weight: bold;
  border: 2px solid #ffffff;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.cgb-server-details .cgb-playlist-container li {
  padding: 5px;
  background-color: #333;
  margin-bottom: 5px;
  border-radius: 4px;
}

.cgb-server-details .cgb-playlist-container li:hover {
  background-color: #444;
  color: #fff;
}

.cgb-server-info {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.cgb-info-item {
  display: flex;
  justify-content: space-between; /* Keep label and value on opposite sides */
  padding: 10px;
  background-color: #333; /* Darker box for each info item */
  transition: background-color 0.3s ease;
}



.cgb-info-item strong {
  color: #fff; /* Bold label text */
  font-weight: bold;
}

.cgb-info-item span {
  color: #ddd; /* Lighter color for the values */
}

/* Highlight for the current playing map */
.cgb-player-list-container {
  background-color: #2a2a2a;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Player List Styling */
.cgb-player-list {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 10px; /* Space between player items */
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 15px 0;
  color: #bbb;
}

.cgb-player-list li {
  padding: 8px;
  background-color: #0c0c0c;
  border-radius: 6px;
  transition: background-color 0.3s ease;
  text-align: center; /* Center align player names */
}

.cgb-player-list li:hover {
  background-color: rgb(82, 0, 107);
  color: #fff;
}

.cgb-current-map:hover {
  background-color: #9933cc; /* Slightly lighter highlight on hover */
  border-color: #ddd; /* Change border color for hover */
}

.cgb-playlists-title {
  font-size: 1.5em; /* Adjust size as needed, e.g., 1.5em or 24px */
  font-weight: bold; /* You can make it bold if you'd like more emphasis */
  color: #ffffff; /* Ensure it contrasts well against the background */
}

/* Introduction Page Styling */
.cgb-intro-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh; /* Makes it take most of the screen height */
  text-align: center;
  padding: 50px;
}

.cgb-intro-content {
  max-width: 800px;
  border-radius: 50px;
  border: 2px solid #6c3483;
  padding: 30px;
  background: linear-gradient(135deg, #3d0a5f, #000000);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #17bbc6;
}

.cgb-intro-content:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

.cgb-intro-content h1 {
  font-size: 2.5em; /* Larger title for emphasis */
  margin-bottom: 20px;
  color: #17bbc6; /* Bright white to stand out */
  font-weight: bold;
}

.cgb-intro-description {
  font-size: 1.2em;
  margin-bottom: 30px;
  color: #ccc; /* Slightly lighter gray for easy reading */
}

.cgb-navigation-info {
  background-color: #222;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.cgb-navigation-info h2 {
  font-size: 1.75em;
  color: #17bbc6; /* A color that ties in with the theme */
  margin-bottom: 15px;
}

.cgb-navigation-info ul {
  list-style: disc inside;
  color: #ddd; /* Light gray for better readability */
  text-align: left; /* Align text to the left */
  padding-left: 20px;
}

.cgb-navigation-info li {
  margin: 10px 0;
}

.cgb-no-servers-available {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: #2a2a2a; /* Background matches the theme */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #ccc; /* Light gray for text */
  opacity: 0; /* Start invisible */
  animation: fadeInUp 1s ease forwards; /* Apply fade-in-up animation */
  animation-delay: 2s; /* Wait for 0.5 seconds before starting the animation */
}

@keyframes fadeInUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  50% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.cgb-no-servers-available img {
  max-width: 80%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.cgb-no-servers-available p {
  font-size: 1.2em;
  color: #ffffff; /* Bright white for emphasis */
}


cgb-ul {
  list-style: none; /* Removes bullet points */
  padding: 0; /* Removes padding */
  margin: 0; /* Removes margin */
}