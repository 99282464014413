html {
  background-color: rgb(12, 11, 11);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Nav {
  background-color: rgb(10, 10, 10);
  position: absolute;
  width: 100%;
  text-align: center;
  opacity: 100%;
}

.HomeText {
  position: absolute;
  color: rgb(255, 255, 255);
  width: 100%;
  text-align: center;
  top: 80px;
}

@media all and (max-width: 600px) {


  html {
    background-color: rgb(12, 11, 11);
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .Nav {
    background-color: rgb(10, 10, 10);
    position: absolute;
    width: 100%;
    text-align: center;
    opacity: 100%;
  }
  
  .HomeText {
    position: absolute;
    color: rgb(255, 255, 255);
    width: 100%;
    text-align: center;
    top: 80px;
  }
  
 }