@media (max-width: 768px) {
    .discord-container {
      display: flex;
      flex-direction: column; /* Stack widgets vertically */
      justify-content: center; /* Center widgets vertically */
      align-items: center; /* Center widgets horizontally */
      gap: 10px; /* Create a small gap between widgets */
      padding: 10px; /* Add subtle padding around the container */
      margin: 0 auto; /* Ensure container is centered horizontally */
    }
  
    .discord-server-widget {
      border: 2px solid rgb(82, 0, 107); /* Keep border consistent */
      background: linear-gradient(135deg, #3d0a5f, #000000); /* Gradient background */
      padding: 15px; /* Internal padding */
      border-radius: 20px; /* Rounded corners */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow */
      margin: 0 auto; /* Center each widget horizontally */
    }
  
    .discord-server-name {
      color: #17bbc6; /* Keep name color consistent */
      font-size: 18px; /* Smaller font size for mobile */
      margin-bottom: 5px; /* Reduce spacing below the name */
      text-align: center; /* Center-align text */
    }
  
    iframe {
      width: 100%; /* Ensure iframe fills the widget */
      height: 450px; /* Keep consistent height for mobile */
      border: none; /* Remove iframe border */
      border-radius: 10px; /* Match widget design */
    }
  }
  