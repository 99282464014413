/* Desktop Styles for DiscordServers Component */
.discord-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.discord-server-widget {
  border: 2px solid rgb(82, 0, 107);
  margin-top: 155px;
  background: linear-gradient(135deg, #3d0a5f, #000000);
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.discord-server-name {
  color: #007bff;
  font-size: 20px;
  margin-bottom: 10px;
}

.discord-shinobi {
  color: #556b2f; 
}

.discord-halo-hangout {
  color: #00008b;
}
