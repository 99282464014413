/* Tickets_Mobile.css */
@media (max-width: 768px) {
    .ticket-form-container {
      width: 100%;
      padding: 1.5em;
      border: 2px solid #5a189a;
      border-radius: 10px;
      background-color: #222;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      text-align: center;
      margin: auto;
      margin-top: 35px;
    }
  
    .ticket-form-container h2 {
      font-size: 1.5em;
      margin-bottom: 1em;
      text-align: center;
    }
  
    .ticket-form-box {
      display: flex;
      flex-direction: column;
    }
  
    .ticket-form-box input,
    .ticket-form-box select,
    .ticket-form-box textarea {
      margin: 0.5em 0;
      padding: 8px;
      font-size: 14px;
      border: 1px solid #5a189a;
      border-radius: 5px;
      background-color: #333;
      color: #fff;
    }
  
    .ticket-form-box select {
      appearance: none;
    }
  
    .ticket-form-box textarea {
      resize: vertical;
      height: 200px;
    }
  
    .ticket-button-group {
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }
  
    .ticket-button {
      background: linear-gradient(135deg, #3d0a5f, #000000);
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 10px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.3s;
    }
  
    .ticket-button:hover {
      background-color: rgb(82, 0, 107);
    }
  
    .ticket-success-message,
    .ticket-error-message {
      font-size: 14px;
      margin-top: 1em;
    }
  
    .ticket-form-box input[name="discordUsername"] {
      margin: 1em 0;
      padding: 8px;
      font-size: 14px;
      border: 1px solid #5a189a;
      border-radius: 5px;
      background-color: #333;
      color: #fff;
    }
  
    /* Adjust text alignment for smaller screens */
    .ticket-form-container,
    .ticket-form-box {
      text-align: left;
    }
  
    /* Ensure the submit button is easy to tap */
    .ticket-button {
      width: 100%;
      padding: 12px;
    }

    .ticket-info-message {
      font-size: 0.9em;
      color: #7a7a7a;
      margin-top: 10px;
      text-align: center;
    }
  }
  