.register-form-container {
    width: 400px;
    margin: auto;
    padding: 2em;
    border: 2px solid #5a189a;
    border-radius: 10px;
    background-color: #222;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .register-form-box {
    display: flex;
    flex-direction: column;
  }
  
  .register-form-box input {
    margin: 0.5em 0;
    padding: 10px;
    font-size: 16px;
  }
  
  .register-button-group {
    display: flex;
    justify-content: space-around;
  }
  
  .register-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .register-error-message {
    color: red;
    margin-top: 10px;
  }
  
  .register-success-message {
    color: green;
    margin-top: 10px;
  }
  
  .register-button-group {
    margin-top: 10%;
  }

  .register-button {
    background: linear-gradient(135deg, #3d0a5f, #000000);
    margin-bottom: 20px;
    color: #fff; /* White text for contrast */
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .register-button:hover {
    background-color: rgb(82, 0, 107); /* Darker blue on hover */
  }

  .toggle-mode {
    color: rgb(0, 179, 255);
    text-decoration: underline;
    cursor: pointer;
  }
  