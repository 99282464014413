.speedruns-page-container {
    display: flex;
    height: 100vh;
    color: #ffffff;
    justify-content: center;
    font-family: 'Arial', sans-serif;
}


.speedruns-content-container {
    flex: 1;
    gap: 20px;
    max-width: 1200px; /* Restrict maximum width */
    transition: margin-right 0.6s, margin-left 0.6s ease;
}

.speedruns-sidebar-visible {
    margin-right: 270px;
    margin-left: -2%;
}

/* Sidebar Styles */
.speedruns-right-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 300px;
    background-color: #222;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;
    z-index: 1000;
    overflow-y: auto;
    border-left: 4px solid rgb(82, 0, 107);
  }
  
  .speedruns-right-sidebar.hidden {
    transform: translateX(100%);
  }
  
  .speedruns-right-sidebar.visible {
    transform: translateX(0);
  }
  
  .speedruns-game-switch-button {
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    width: 82%;
    cursor: pointer;
    color: white;
    background: linear-gradient(135deg, #3d0a5f, #000000);
    transition: background-position 0.3s, background 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}

.speedruns-game-switch-button:hover {
    transform: scale(1.05); /* Slight zoom-in effect on hover */
    background: linear-gradient(135deg, #3d0a5f, #42234a);
}

.speedruns-rules-container, .speedruns-record-details {
    background: #1e1e1e;
    padding: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
}



.speedruns-details-text {
    background: #2a2a2a;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    border: 2px solid rgb(82, 0, 107);
}

.speedruns-speedrun-entry {
    background: #333;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.speedruns-speedrun-entry a {
    color: #00bfff; /* Link color */
    text-decoration: none;
}

.speedruns-speedrun-entry a:hover {
    text-decoration: underline;
}

.speedruns-rules-container {
    border-radius: 50px;
    border: 2px solid #6c3483;
    padding: 30px;
    background: linear-gradient(135deg, #3d0a5f, #000000);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
    color: #ffffff;
}

.speedruns-rules-container h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #17bbc6; /* Main heading color */
}

.speedruns-rules-section {
    margin-bottom: 30px;
    background: #2a2a2a;
    padding: 20px;
    border-radius: 8px;
    border-left: 5px solid #3d0a5f;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.speedruns-rules-section h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #17bbc6;
}

.speedruns-rules-section p {
    line-height: 1.6;
    font-size: 1rem;
    color: #d3d3d3;
}

.speedruns-map-specific-section {
    margin-top: 20px;
    padding: 20px;
    background: #333333;
    border-radius: 8px;
    border-left: 5px solid #3d0a5f;
}

.speedruns-map-dropdown {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-size: 1rem;
    background: #444;
    color: #ffffff;
    border: none;
    border-radius: 4px;
}

.speedruns-map-dropdown:focus {
    outline: none;
    border: 1px solid #00bfff;
}

.speedruns-map-specific-rules p {
    margin-top: 15px;
    font-size: 1rem;
    color: #b0e0e6;
}

.speedruns-map-specific-section h2 {
    color: #17bbc6;
}

.speedruns-rules-section ul {
    padding-left: 20px;
    list-style: disc;
    color: #b0e0e6;
    font-size: 1rem;
}

.speedruns-rules-container .speedruns-rules-section {
    transition: transform 0.3s, box-shadow 0.3s;
}

.speedruns-rules-container .speedruns-rules-section:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.6);
}

.speedruns-rules-section code {
    background: #444;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 0.95rem;
    color: #ffa500;
}

.speedruns-rules-container hr {
    border: none;
    height: 1px;
    background: #444;
    margin: 20px 0;
}

.speedruns-record-details {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Split evenly between two columns */
    gap: 20px;
    background: #1e1e1e;
    padding: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
    border: 2px solid rgb(82, 0, 107);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.speedruns-map-image {
    flex: 1; /* Take half the container */
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.speedruns-details-text {
    flex: 1; /* Take half the container */
    background: #2a2a2a;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.speedruns-speedrun-entry {
    background: #333;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.speedruns-speedrun-entry a {
    color: #00bfff; /* Link color */
    text-decoration: none;
}

.speedruns-speedrun-entry a:hover {
    text-decoration: underline;
}

.speedruns-map-details {
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  justify-content: space-between; /* Keep label and value on opposite sides */
  height: 100%;
}

.speedruns-map-details-item {
  display: flex;
  justify-content: space-between; /* Keep label and value on opposite sides */
  padding: 10px;
  background-color: #333; /* Darker box for each info item */
  transition: background-color 0.3s ease;
}

.speedruns-button-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 100%; /* Ensure container takes the available height */
}

.speedruns-center-button {
    padding: 10px 20px;
    background: linear-gradient(135deg, #3d0a5f, #000000);
    color: #ffffff; /* White text color */
    border: none; /* Remove default border */
    border-radius: 8px; /* Rounded corners for a clean look */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1rem; /* Medium-sized font */
    font-family: 'Arial', sans-serif; /* Clean font style */
    transition: background 0.3s, transform 0.2s; /* Smooth transition for hover effects */
}

.speedruns-center-button:hover {
    background: linear-gradient(135deg, #3d0a5f, #42234a);
    transform: scale(1.05); /* Slight zoom-in effect on hover */
}

.speedruns-map-list {
    list-style: none;
    padding: 0 20px;
    margin: 0;
}

.speedruns-map-item {
    padding: 10px;
    margin-bottom: 5px;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}
  
  .speedruns-map-item:hover {
    background-color: #444;
}
  
  .speedruns-map-item.active {
    background: linear-gradient(135deg, #3d0a5f, #000000);
    color: #ffffff;
}

.speedruns-sidebar-toggle-button {
    position: fixed;
    top: 3%;
    right: 310px;
    transform: translateY(-50%);
    background-color: #333;
    color: #fff;
    border: 2px solid #555;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    z-index: 1001;
    font-size: 1.2em;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .speedruns-sidebar-toggle-button.hidden {
    right: 10px;
  }

  .speedruns-sidebar-toggle-button:hover {
    background-color: #555;
    border-color: #777;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  .speedruns-crowne-image {
    width: 30px; /* Set the width of the crown */
    height: auto; /* Maintain the aspect ratio */
    margin-right: 8px; /* Add some spacing between the crown and text */
    vertical-align: middle; /* Align the crown vertically with the text */
}