/* userprofile.css */
body {
    background-color: #121212;
    color: #ffffff;
    font-family: Arial, sans-serif;
  }
  
  .profile-user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    width: 1200px;
    overflow-x: hidden !important;
  }
  
  .profile-banner {
    width: 90%; /* Match profile-info's width */
    max-width: 600px; /* Match profile-info's max-width */
    height: 200px;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 10px; /* Rounding matches profile-info */
    border-top-right-radius: 10px; /* Rounding matches profile-info */
    border-bottom: 0; /* Remove bottom border entirely */
    margin: 0; /* Remove any margin affecting layout */
    border: 2px solid #5a189a;
}

.profile-profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #1e1e1e;
    padding: 2em;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
    border-radius: 10px; /* Ensure the full block matches the banner */
    border-top-left-radius: 0; /* Remove top radius (handled by banner) */
    border-top-right-radius: 0; /* Remove top radius (handled by banner) */
    width: 90%; /* Match banner width */
    max-width: 600px; /* Match banner width */
    margin-top: px; /* Slight overlap to eliminate visible gap */
    padding-top: 9%; /* Adjust for spacing */
    border: 2px solid #5a189a; /* Purple border accent */
}

  
  .profile-profile-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid #5a189a;
    margin-bottom: 1em;
  }

   .profile-profile-pic.half-overlap {
    position: relative;
    top: 100%;
    transform: translateY(-50%);
    border: 4px solid #5a189a;
  }
  
  .profile-username {
    font-size: 1.8em;
    font-weight: bold;
    color: #ffffff;
    margin-top: 0.5em;
  }
  
  .profile-status {
    margin: 1em 0;
    color: #8ac926; /* Green text for 'Online' status */
  }
  
  .profile-bio {
    font-style: italic;
    color: #cfcfcf;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  
  .profile-edit-section {
    display: grid;
    grid-template-columns: 2fr 1fr; /* Two columns, adjust as needed */
    justify-items: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically */
    background: #1e1e1e;
    border-radius: 10px;
    padding: 1.5em;
    margin-top: 2em;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
    width: 50%;
    border: 2px solid #5a189a; /* Purple accent */
    gap: 1em; /* Optional: Adds spacing between grid items */
}
  
  .profile-edit-field {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

  }

  .profile-edit-field input {
    background-color: rgb(80, 80, 80);
    color: white; /* Optional: Adjust text color for contrast */
    border: 1px solid #ccc; /* Optional: Add a border for better visibility */
    padding: 8px; /* Optional: Add padding for better spacing */
    border-radius: 4px; /* Optional: Smooth edges */
}
  
  .profile-save-button {
    margin-top: 21px;
    height: 47%; /* Matches the height of the parent container or inline elements */
    background: linear-gradient(135deg, #3d0a5f, #000000);
    color:#ffffff;
    padding: 0; /* Ensures padding doesn't add to the height */
    display: inline-block; /* Keeps the button inline with the input */
    vertical-align: middle; /* Aligns properly with the input field */
    border-radius: 5px;
    background: linear-gradient(135deg, #3d0a5f, #000000);
    transition: background-position 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}

.profile-save-button:hover {
    transform: scale(1.05); /* Slight zoom-in effect on hover */
    background: linear-gradient(135deg, #3d0a5f, #42234a);
}

  profile-label {
    font-weight: bold;
    color: #5a189a; /* Purple accent */
    margin-bottom: 0.5em;
  }
  
  profile-input, profile-textarea {
    background: #333333;
    color: #ffffff;
    border: 1px solid #5a189a; /* Purple accent */
    border-radius: 5px;
    padding: 0.5em;
  }
  
  profile-input:focus, profile-textarea:focus {
    outline: none;
    border-color: #8ac926; /* Highlight input with a green accent when focused */
  }

 