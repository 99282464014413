/* Navbar container */
.navbar-container {
  display: flex;
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
}

/* Sidebar styles */
.navbar-sidebar-menu {
  width: 200px !important; /* Force the width */
  background-color: #222 !important ;
  overflow-x: hidden;
  transition: width 0.3s ease;
  position: fixed;
  height: 100vh;
}

.navbar-sidebar-hidden {
  width: 0px !important; /* Hide sidebar when collapsed */
}

.navbar-sidebar-logo img {
  width: 160px;
  margin-bottom: 1em !important;
}

.navbar-sidebar-profile-icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
}

.navbar-profile-dropdown .menu {
  width: calc(100% - 1px) !important; /* Ensure dropdown spans almost full sidebar width */
  left: 0 !important; /* Align to the left of the sidebar */
  position: absolute;
  background-color: #333; /* Dropdown background color */
  border-radius: 5px; /* Optional: Rounded corners */
  margin-top: 5px; /* Add a small gap between the dropdown and trigger */
  z-index: 1000; /* Ensure it appears above other elements */
}

.navbar-profile-dropdown .menu .item {
  color: #fff !important; /* Text color */
  padding: 10px 15px !important; /* Padding around items */
  transition: background 0.3s ease !important; /* Smooth hover effect */
}

.navbar-profile-dropdown .menu .item:hover {
  background-color: #444 !important; /* Hover background */
  color: #00b5ad !important; /* Hover text color */
}

.navbar-profile-dropdown img {
  width: 40px; /* Profile picture size */
  height: 40px;
  border-radius: 50%; /* Circular profile picture */
  object-fit: cover;
}

/* Main content styles */
.navbar-main-content {
  flex: 1;
  padding: 1em;
  margin-left: 200px;
  overflow-x: hidden;
  position: relative;
  transition: margin-left 0.6s ease, width 0.6s ease; /* Transition when expanding */
}

.navbar-main-content-collapsed {
  margin-left: 0px;
  width: 100%;
}

/* Toggle button */
.navbar-toggle-button {
  position: fixed;
  top: 0.01%;
  background-color: #333;
  color: #c8c8c8;
  border: 2px solid #555;
  border-radius: 8px;
  cursor: pointer;
  z-index: 998;
  font-size: 1.2em;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.45s ease; /* Smooth transition */
}

.navbar-toggle-button button {
  all: unset; /* Reset all button styles to inherit from parent */
  background-color: #333; /* Match the parent container's background */
  color: #e0e0e0; /* White text color */
  border: none; /* Remove border */
  padding: 10px 15px; /* Add padding for click area */
  border-radius: 8px; /* Optional: Keep rounded corners */
  cursor: pointer; /* Pointer cursor for clickable element */
  font-size: 1.2em; /* Text size */
  font-weight: bold; /* Bold text */
  transition: all 0.3s ease; /* Smooth transition */
}

.navbar-sidebar-toggle-left {
  left: 10px;
}

.navbar-sidebar-toggle-right {
  left: 210px;
}

.navbar-sidebar-login-button {
  text-align: center;
  padding: 1em 0;
}

.navbar-sidebar-login-button .navbar-icon {
  margin-right: 5px;
}

.navbar-profile-dropdown img {
  width: 10%; /* Relative width to parent container */
  max-width: 50px; /* Limit the size on larger screens */
  height: auto; /* Maintain aspect ratio */
  border-radius: 50%; /* Make it circular */
  object-fit: cover; /* Ensure the image covers the space without distortion */
  vertical-align: middle;
}
