.ticket-form-container {
    width: 400px;
    margin: auto;
    padding: 2em;
    border: 2px solid #5a189a;
    border-radius: 10px;
    background-color: #222;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .ticket-form-box {
    display: flex;
    flex-direction: column;
  }
  
  .ticket-form-box input,
  .ticket-form-box select,
  .ticket-form-box textarea {
    margin: 0.5em 0;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #5a189a;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
  }
  
  .ticket-form-box select {
    appearance: none;
  }
  
  .ticket-form-box textarea {
    resize: vertical;
    height: 100px;
  }
  
  .ticket-button-group {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }
  
  .ticket-button {
    color: #fff !important;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background: linear-gradient(135deg, #3d0a5f, #000000);
    transition: background-position 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}

.ticket-button:hover {
    transform: scale(1.05); /* Slight zoom-in effect on hover */
    background: linear-gradient(135deg, #000000, #3d0a5f);
}
  
  .ticket-success-message {
    color: green;
    margin-top: 1em;
  }
  
  .ticket-error-message {
    color: red;
    margin-top: 1em;
  }
  
  .ticket-form-box input[name="discordUsername"] {
    margin: 0.5em 0;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #5a189a;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
  }

  .ticket-info-message {
    font-size: 0.9em;
    color: #7a7a7a;
    margin-top: 10px;
    text-align: center;
  }