@media all and (max-width: 600px) {
    /* General body adjustments */
    body {
        margin: 0;
        padding: 0;
        background-color: #121212;
        color: #ffffff;
        font-family: Arial, sans-serif;
    }
    .profile-user-profile {
        margin-left: 4%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2em;
        max-width: 130%;
      }
    /* Banner */
    .profile-banner {
        width: 95%; /* Slightly reduce width for better alignment */
        height: 250px; /* Maintain proportional height */
        background-size: cover;
        background-position: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 2px solid #5a189a;
        margin-bottom: -110px; /* Ensure profile picture overlaps nicely */
    }

    /* Profile Picture */
    .profile-profile-pic {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 4px solid #5a189a;
        margin-bottom: 1em;
      }

    .profile-profile-pic.half-overlap {
        top: 55%;
    }

    /* Profile Info */
    .profile-profile-info {
        background: #1e1e1e;
        padding: 2em 1em;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 2px solid #5a189a;
        width: 95%;
        max-width: 500px;
        padding-top: 15%;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
        text-align: center;
    }

    .profile-username {
        font-size: 1.5em;
        font-weight: bold;
        color: #ffffff;
    }

    .profile-bio {
        font-size: 1em;
        color: #cfcfcf;
        margin-top: 0.5em;
        font-style: italic;
    }

    .profile-xbox-gamertag {
        font-size: 0.9em;
        margin-top: 0.5em;
    }

    /* Edit Section */
    .profile-edit-section {
        background: #1e1e1e;
        border-radius: 10px;
        border: 2px solid #5a189a;
        width: 95%; /* Take up most of the screen width */
        max-width: 500px;
        margin: 1.5em auto;
        padding: 1em;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        gap: 1em; /* Add space between edit fields */
    }

    .profile-edit-field {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .profile-edit-field label {
        font-weight: bold;
        color: #ffffff;
        font-size: 0.9em;
    }

    .profile-edit-field input {
        background-color: rgb(80, 80, 80);
        color: white;
        border: 1px solid #ccc;
        padding: 8px;
        border-radius: 4px;
        font-size: 0.9em;
        width: 100%;
    }

    /* Save Button */
    .profile-save-button {
        padding: 10px;
        font-size: 1em; /* Slightly larger font for readability */
        width: 100%; /* Full-width buttons */
        border-radius: 5px;
        background: linear-gradient(135deg, #3d0a5f, #000000);
        color: #ffffff;
        border: none;
        text-align: center;
        transition: background 0.3s, transform 0.3s;
    }

    .profile-save-button:hover {
        transform: scale(1.05);
        background: linear-gradient(135deg, #3d0a5f, #42234a);
    }

    /* Save Message */
    .profile-save-message {
        text-align: center;
        font-size: 0.9em;
        color: #8ac926;
        font-weight: bold;
        margin-top: 1em;
    }
}
